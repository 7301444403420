
.burger {
  position: fixed;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  z-index: 2000;
  width: 64px;
  height: 64px;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.opened {

}

.burger:hover .line1,
.burger:hover .line2,
.burger:hover .line3
{
  stroke-width: 9;
  transition-timing-function: ease-out;
}

.line {
  fill: none;
  stroke: #41EAC1;
  stroke-width: 6;
  transition: 
    stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-width 0.3s ease;
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
  stroke: 41EAC1;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
  stroke: 41EAC1;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
  stroke: 41EAC1;
}
