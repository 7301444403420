.half-half-gallery {
  display: flex;
  flex-wrap: wrap;
}

figure {
  background: #eee;
  width: calc(50% + 1px);
  height: 100vh;
  margin: 0 auto 10vh 0;
  position: sticky;
  top: 0;
  overflow: hidden;
  box-shadow: 4px -4px 8px rgba(0, 0, 0, .4);
}

figure:nth-of-type(2n)::after {
  right: 45%;
  left: 5vmin;
}

.half-text-section {
  box-sizing: border-box;
  background: #e5e5e5;
  width: calc(50% + 1px);
  height: 100vh;
  margin: 0 0 10vh auto;
  position: sticky;
  top: 0;
  padding: 5vmin;
  box-shadow: -4px -4px 8px rgba(0, 0, 0, .4);
}

figure:nth-of-type(1),
.half-text-section:nth-of-type(1) {
  margin: 0 0 10vh 0;
  width: 50%;
}

figure:nth-of-type(2n) {
  margin: 0 0 10vh auto;
  box-shadow: -4px -4px 8px rgba(0, 0, 0, .4);
}

.half-text-section:nth-of-type(2n) {
  margin: 0 auto 10vh 0;
  box-shadow: 4px -4px 8px rgba(0, 0, 0, .4);
}

figure:last-of-type,
.half-text-section:last-of-type {
  margin-bottom: 0;
}

.half-text-section::before {
  background: inherit;
  z-index: 1;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 7vmin;
  height: 7vmin;
  transform: translate(calc(-50% + 1px), -50%) rotate(-45deg);
  clip-path: polygon(-15% -15%, 110% 0%, 0% 110%);
  box-shadow: -4px -2px 8px rgba(0, 0, 0, .4);
  border-radius: 1.5vmin 0 0 0;
}

.half-text-section:nth-of-type(2n)::before {
  left: auto;
  right: 0;
  transform: translate(calc(50% - 1px), -50%) rotate(-45deg) scale(-1);
}

.half-text-section:nth-of-type(2n):after {
  right: 5vmin;
  left: 45%;
}

figure img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center;
}
