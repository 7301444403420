/*
.motion-base-image {
  background-image: url("photos/motion.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.motion-blur-image {
  background-image: url("photos/motion-blur.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
*/

.motion-image {
  cursor: pointer;
}

.motion-base-image {
  background-image: url("photos/motion.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.motion-blur-image {
  background-image: url("photos/motion-blur.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.motion-text-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid white;
  animation: unblur 1s forwards cubic-bezier(0.5, 1, 0.89, 1);
  filter: blur(4px);
  transition: opacity 0.5s linear;
}

.motion-text-fade-out {
  opacity: 0;
}

@keyframes unblur {
  100% {
    filter: blur(0);
  }
}

.motion-gallery-text { 
  font-size: clamp(5vh, 2rem);
}