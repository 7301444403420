@keyframes load {
  0% {
    opacity: 0.08;
    filter: blur(5px);
    letter-spacing: 3px;
  }
}

.blur-loader {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
  font-family: 'Archivo Black', sans-serif, Arial;
  animation: load 1.2s infinite 0s ease-in-out;
  animation-direction: alternate;
  text-shadow: 0 0 1px black;
}