.menu {
  position: absolute;
  width: 0vw;
  height: 0vh;
  background-color: white;
  opacity: 0;
  transition: opacity 0.25s ease, width 0.25s ease, height 0.25s ease;
  z-index: 3;
  overflow: hidden;
}

.menu-show {
  width: 100vw;
  height: 100vh; 
  opacity: 1;
}
