.header-container {
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: relative;
  /*border-top: 5px dotted black;*/
}

.header-container-small {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.header-photo-container {
  position: relative;
}

.header-photo {
  -webkit-transition: 5s -webkit-filter linear;
  -moz-transition: 5s -moz-filter linear;
  -moz-transition: 5s filter linear;
  -ms-transition: 5s -ms-filter linear;
  -o-transition: 5s -o-filter linear;
  transition: 5s filter linear, 5s -webkit-filter linear;
}

.header-photo:hover {
  filter: contrast(118%);
}

.header-photo-container:before,
.header-photo-container:after {
  content: "";
  position: absolute;
  background-color: rgba(102, 102, 102, 0.3);
}

.header-photo-container:before {
  top: 3px;
  left: -55px;
  transform: rotate(-40deg);
  height: 50px;
  width: 180px;
  border-left: 1.8px dashed white;
  border-right: 1.8px dashed white;
  z-index: 1;
}

.header-photo-container:after {
  bottom: 3px;
  right: -50px;
  transform: rotate(-40deg);
  height: 50px;
  width: 180px;
  border-left: 1.8px dashed white;
  border-right: 1.8px dashed white;
  z-index: 1;
}


.header-tagline {
  font-family: 'Archivo Black', sans-serif;
  padding: 50px;
  border: 5px solid black;
  width: 280px;
  font-size: 40px;
  text-align: "center";
}


.header-tagline-small {
  color: white;
  font-family: 'Archivo Black', sans-serif;
  padding: 20px;
  border: 5px solid white;
  width: 250px;
  font-size: 32px;
  text-align: "center";
  background-color: rgba(0, 0, 0, 0.5);
}

.header-photo-small {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}