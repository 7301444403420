.polaroid {
  position: relative;
  background-color: #eaeaea;
  padding: 19px 19px 19px 19px;
  transition: .3s;
  cursor: pointer;
  max-width: 400px;
}

.polaroid-hover {
  transform: scale(1.1);

}

.polaroid-gray {
  filter: grayscale(1);
}

.polaroid img {
  width: 100%;
}

.polaroid-caption {
  font-family: 'Caveat', cursive;
  font-size: 25px;
  text-align: center;
  color: #2c2c2c;
  margin-top: 22px;
}

.polaroid:before,
.polaroid:after {
  content: "";
  position: absolute;
  background-color: rgba(102, 102, 102, 0.3);
}

.polaroid-tape1:before {
  top: 3px;
  left: -55px;
  transform: rotate(-40deg);
  height: 50px;
  width: 180px;
  border-left: 1.8px dashed black;
  border-right: 1.8px dashed black;
}

.polaroid-tape1:after {
  bottom: 3px;
  right: -60px;
  transform: rotate(-40deg);
  height: 50px;
  width: 180px;
  border-left: 1.8px dashed black;
  border-right: 1.8px dashed black;
}

.polaroid-tape2:before {
  bottom: 3px;
  left: -60px;
  transform: rotate(40deg);
  height: 50px;
  width: 180px;
  border-left: 1.8px dashed black;
  border-right: 1.8px dashed black;
}

.polaroid-tape2:after {
  top: 3px;
  right: -55px;
  transform: rotate(40deg);
  height: 50px;
  width: 180px;
  border-left: 1.8px dashed black;
  border-right: 1.8px dashed black;
}

.polaroid-tape3:before {
  top: -25px;
  left: 50%;
  translate: -50%;
  transform: rotate(0deg);
  height: 50px;
  width: 180px;
}

