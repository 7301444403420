
.scroll-arrow,
.scroll-arrow:before {
  position: absolute;
  left: 50%;
}

.scroll-arrow {
  width: 40px;
  height: 40px;
  top: 50%;
  margin: -20px 0 0 -20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #fff solid;
  border-bottom: 2px #fff solid;
}

.scroll-arrow:before {
  content: '';
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px #fff solid;
  border-bottom: 1px #fff solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}
